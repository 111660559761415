export default [
    {
        _name: 'CSidebarNav',
        _children: [
            {
                _name: 'CSidebarNavItem',
                name: 'Reward Types',
                to: '/settings/reward-types',
                icon: 'cil-star',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Tags',
                to: '/settings/tags',
                icon: 'cil-tags',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Action Types',
                to: '/settings/action-types',
                icon: 'cil-user',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Custom Fields',
                to: '/settings/custom-fields',
                icon: 'cil-magnifying-glass',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Languages',
                to: '/settings/languages',
                icon: 'cilTextSquare',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Units Of Measures',
                to: '/settings/units-of-measure',
                icon: 'cilBalanceScale',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Collaborators',
                to: '/settings/collaborators',
                icon: 'cil-people',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'ApiKeys',
                to: '/settings/api-keys',
                icon: 'cil-layers',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Transformers',
                to: '/settings/transformers',
                icon: 'cil-globe-alt',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Webhooks',
                to: '/settings/webhooks',
                icon: 'cil-laptop',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Connections',
                to: '/settings/connections',
                icon: 'cil-settings',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Compute Engines',
                to: '/settings/compute-engines',
                icon: 'cil-laptop',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            {
                _name: 'CSidebarNavItem',
                name: 'System Logs',
                to: '/settings/log-events',
                icon: 'cil-description',
                _attrs: {class: 'c-sidebar-nav-item'},
            }
        ]
    }
]