export default [
    {
        _name: 'CSidebarNav',
        _children: [
            {
                _name: 'CSidebarNavItem',
                name: 'Inbox',
                to: '/inbox',
                icon: 'cilTextSquare',
                _attrs: {class: 'c-sidebar-nav-item'},
            },
            // {
            //     _name: 'CSidebarNavItem',
            //     name: 'Draft',
            //     to: '/draft',
            //     icon: 'cil-pencil',
            //     _attrs: {class: 'c-sidebar-nav-item'},
            // },
            // {
            //     _name: 'CSidebarNavItem',
            //     name: 'Sent',
            //     to: '/sent',
            //     icon: 'cil-user',
            //     _attrs: {class: 'c-sidebar-nav-item'},
            // },
            // {
            //     _name: 'CSidebarNavItem',
            //     name: 'Deleted',
            //     to: '/deleted',
            //     icon: 'cil-trash',
            //     _attrs: {class: 'c-sidebar-nav-item'},
            // },
            // {
            //     _name: 'CSidebarNavItem',
            //     name: 'Template',
            //     to: '/template',
            //     icon: 'cil-text-square',
            //     _attrs: {class: 'c-sidebar-nav-item disabled'},
            // },
        ]
    }
]