<template>
  <CFooter :fixed="false">
    <div>
      <a :href="app.url" target="_blank">{{ app.name }}</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}.</span>
    </div>
    <div
      class="mfs-auto d-flex align-items-end justify-content-center flex-wrap"
    >
      <span class="mr-1">Powered by</span>
      <span class="mr-2">{{ app.domain }}</span>
      <a :href="app.privacy" class="mr-2" target="_blank">Privacy Policy</a>
      <a :href="app.term" target="_blank">TermsOfService</a>
    </div>
  </CFooter>
</template>

<script>
import { app } from '../config/appConstantsConfig.js';
export default {
  name: 'TheFooter',
  data() {
    return { app: app };
  },
  mounted() {
    this.setHelpdeskWidget();
  },
  methods: {
    setHelpdeskWidget() {
      window.fwSettings={
        'widget_id':101000002128
      };
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}();
      const widgetScript = document.createElement('script');
      widgetScript.setAttribute('src', 'https://euc-widget.freshworks.com/widgets/101000002128.js');
      widgetScript.setAttribute('async', 'true');
      widgetScript.setAttribute('defer', 'true');
      document.body.appendChild(widgetScript);
    }
  },
};
</script>
