import { isProd, isStaging } from '@/helpers/keycloak';

export const appDomain = `${
  isProd ? '' : isStaging ? 'staging.' : 'local.'
} ziqni.app`;
export const app = {
  name: 'Ziqni',
  domain: 'ziqni.app',
  url: 'https://ziqni.app',
  privacy: 'https://www.ziqni.com/privacy-policy/',
  term: 'https://www.ziqni.com/terms-of-service/',
};
