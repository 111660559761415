<template>
  <CHeader
    fixed
    with-subheader
    light
    :class="{ 'zq-header--dropdown-open': accountDropdown }"
  >
    <!--  SITE NAVIGATION + LOGO BRAND  -->
    <div class="d-flex">
      <CToggler
        v-if="laboratoryPage"
        in-header
        class="d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
      />
      <CToggler
        v-if="laboratoryPage"
        in-header
        class="d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
      />
      <ClSidebarLogo class="space-logo" :minimize="false" />
      <Breadcrumb :laboratoryPage="laboratoryPage" />
    </div>

    <!--   ALERT -->
    <CHeaderNav>
      <Alert :message="message" :success="success" />
    </CHeaderNav>
    <CHeaderNav>
      <Alert :message="idleMessage" :success="false" :isAutoHide="false"/>
    </CHeaderNav>

    <ClHeaderNavActions
      :laboratoryPage="laboratoryPage"
      @dropdownEvent="dropdownEvent"
    />
  </CHeader>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/shared/components/Breadcrumb";
import Alert from "@/components/Alert";
import ClHeaderNavActions from "@/components/header/ClHeaderNavActions";
import ClSidebarLogo from "@/shared/UI/ClSidebarLogo";

export default {
  name: "TheHeader",
  components: {
    Alert,
    Breadcrumb,
    ClHeaderNavActions,
    ClSidebarLogo,
  },
  data() {
    return {
      accountDropdown: true,
      currSeconds: 0,
      timer: null,
      idleMessage: '',
    };
  },
  props: {
    laboratoryPage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("path", ["fullPath", "name", "params"]),
    ...mapGetters("members", {
      membersSuccess: "success",
      membersMessage: "message",
    }),
    ...mapGetters("products", {
      productsSuccess: "success",
      productsMessage: "message",
    }),
    ...mapGetters("fileRepositories", {
      repoSuccess: "success",
      repoMessage: "message",
    }),
    ...mapGetters("files", {
      fileSuccess: "success",
      fileMessage: "message",
    }),
    ...mapGetters("actionTypes", {
      actionTypeSuccess: "success",
      actionTypeMessage: "message",
    }),
    ...mapGetters("rewardTypes", {
      rewardTypeSuccess: "success",
      rewardTypeMessage: "message",
    }),
    ...mapGetters("rewards", {
      rewardsSuccess: "success",
      rewardsMessage: "message",
    }),
    ...mapGetters("rules", {
      rulesSuccess: "success",
      rulesMessage: "message",
    }),
    ...mapGetters("translations", {
      translationsSuccess: "success",
      translationsMessage: "message",
    }),
    ...mapGetters("events", {
      eventsSuccess: "success",
      eventsMessage: "message",
    }),
    ...mapGetters("customFields", {
      customFieldsSuccess: "success",
      customFieldsMessage: "message",
    }),
    ...mapGetters("languages", {
      languagesSuccess: "success",
      languagesMessage: "message",
    }),
    ...mapGetters("unitsOfMeasure", {
      unitsSuccess: "success",
      unitsMessage: "message",
    }),
    ...mapGetters("webhooks", {
      webhooksSuccess: "success",
      webhooksMessage: "message",
    }),
    ...mapGetters("connections", {
      connectionsSuccess: "success",
      connectionsMessage: "message",
    }),
    ...mapGetters("transformers", {
      transformersSuccess: "success",
      transformersMessage: "message",
    }),
    ...mapGetters("apiKeys", {
      apiKeysSuccess: "success",
      apiKeysMessage: "message",
    }),
    ...mapGetters("achievements", {
      achievementsSuccess: "success",
      achievementsMessage: "message",
    }),
    ...mapGetters("competitions", {
      competitionsSuccess: "success",
      competitionsMessage: "message",
    }),
    ...mapGetters("contests", {
      contestsSuccess: "success",
      contestsMessage: "message",
    }),
    ...mapGetters("collaborators", {
      collaboratorsSuccess: "success",
      collaboratorsMessage: "message",
    }),
    ...mapGetters("tags", {
      tagsSuccess: "success",
      tagsMessage: "message",
    }),
    ...mapGetters("rules", {
      rulesSuccess: "success",
      rulesMessage: "message",
    }),
    ...mapGetters("sqsConnections", {
      sqsConnectionsSuccess: "success",
      sqsConnectionsMessage: "message",
    }),
    ...mapGetters("kafkaConnections", {
      kafkaConnectionsSuccess: "success",
      kafkaConnectionsMessage: "message",
    }),
    ...mapGetters("rabbitMqConnections", {
      rabbitMqConnectionsSuccess: "success",
      rabbitMqConnectionsMessage: "message",
    }),
    ...mapGetters("notifications", {
      notificationsSuccess: "success",
      notificationsMessage: "message",
    }),
    ...mapGetters("instantWins", {
      instantWinsSuccess: "success",
      instantWinsMessage: "message",
    }),

    message() {
      return (
        this.sqsConnectionsMessage ||
        this.kafkaConnectionsMessage ||
        this.rabbitMqConnectionsMessage ||
        this.repoMessage ||
        this.membersMessage ||
        this.fileMessage ||
        this.productsMessage ||
        this.actionTypeMessage ||
        this.rewardTypeMessage ||
        this.rewardsMessage ||
        this.rulesMessage ||
        this.translationsMessage ||
        this.eventsMessage ||
        this.customFieldsMessage ||
        this.languagesMessage ||
        this.unitsMessage ||
        this.webhooksMessage ||
        this.connectionsMessage ||
        this.transformersMessage ||
        this.apiKeysMessage ||
        this.achievementsMessage ||
        this.contestsMessage ||
        this.competitionsMessage ||
        this.collaboratorsMessage ||
        this.tagsMessage ||
        this.rulesMessage ||
        this.notificationsMessage ||
        this.instantWinsMessage ||
        undefined
      );
    },
    success() {
      return (
        this.sqsConnectionsSuccess ||
        this.kafkaConnectionsSuccess ||
        this.rabbitMqConnectionsSuccess ||
        this.membersSuccess ||
        this.repoSuccess ||
        this.fileSuccess ||
        this.productsSuccess ||
        this.actionTypeSuccess ||
        this.rewardTypeSuccess ||
        this.rewardsSuccess ||
        this.rulesSuccess ||
        this.translationsSuccess ||
        this.eventsSuccess ||
        this.customFieldsSuccess ||
        this.languagesSuccess ||
        this.unitsSuccess ||
        this.webhooksSuccess ||
        this.connectionsSuccess ||
        this.transformersSuccess ||
        this.apiKeysSuccess ||
        this.achievementsSuccess ||
        this.contestsSuccess ||
        this.competitionsSuccess ||
        this.collaboratorsSuccess ||
        this.tagsSuccess ||
        this.rulesSuccess ||
        this.notificationsSuccess ||
        this.instantWinsSuccess ||
        undefined
      );
    },
  },
  watch: {
    name(val) {
      if (val.includes('Create')) {
        this.resetTimer();
        window.onmousemove = this.resetTimer;
        window.keypress = this.resetTimer;
      } else {
        window.onmousemove = null;
        window.keypress = null;
        clearInterval(this.timer);
      }
    },
    currSeconds(val){
      if (val > 300 && val <= 360) {
        this.idleMessage = 'Your session is about to expire!';
      } else if (val > 360) {
        this.idleMessage = 'This Session Has Timed Out.';
      }
    },
  },
  methods: {
    dropdownEvent(val) {
      this.accountDropdown = val;
    },
    startIdleTimer() {
      this.currSeconds++;
    },
    resetTimer() {
      clearInterval(this.timer);
      this.idleMessage = '';
      this.currSeconds = 0;
      this.timer = setInterval(this.startIdleTimer, 1000);
    }
  },
};
</script>

<style lang="scss">
.c-header {
  display: flex;
  justify-content: space-between;
}

.zq-header--dropdown-open {
  z-index: 1030 !important;
}

.c-header-toggler {
  margin-left: 0.5rem;
}
.space-logo {
  margin-left: -1.5rem;
  margin-right: 0.5rem;
}
</style>
