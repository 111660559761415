<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none">
      <div class="c-sidebar-brand-full">
        <a href="https://www.ziqni.app">
          <img :src="require('@/assets/images/logo.png')" class="logo" />
        </a>
      </div>
      <div class="c-sidebar-brand-minimized">
        <a href="https://www.ziqni.app">
          <img :src="require('@/assets/images/small-logo.png')" />
        </a>
      </div>
    </CSidebarBrand>

    <!-- ARROW  -->
    <div
      v-if="sidebarShow"
      class="diamond-shape d-md-down-none"
      @click="$store.commit('set', ['sidebarMinimize', !minimize])"
    >
      <div class="item-count">
        <CSidebarMinimizer class="d-md-down-none" />
        <div class="item-count--bg"></div>
      </div>
    </div>
    <!--   Settings NAVIGATION -->
    <div
      class="h-100"
      v-if="this.$route.matched.some(({ path }) => path.includes('/settings/'))"
    >
      <div class="settings-back-btn">
        <a class="c-sidebar-nav-link" id="goBackLab" @click="goBack">
          <CIcon
            name="cilArrowCircleLeft"
            class="c-sidebar-nav-icon"
            width="24"
            :height="45"
          >
          </CIcon>
          <span class="go-back-lab--title">Back</span>
        </a>
      </div>
      <div
        class="nav-divider"
        :class="{ 'nav-divider__minimize': minimize }"
      ></div>
      <!-- <div class="settings-back-btn c-sidebar-nav-link" id ="zq-settings-title" :height="35">Ziqni SettingsStep</div>-->
      <ClSidebarItems
        :items="$options.settingsNav[0]._children"
        :isSettingItems="true"
      />
    </div>
    <!--  Messages NAVIGATION -->
<!--    <div-->
<!--      v-else-if="-->
<!--        this.$route.matched.some(-->
<!--          ({ path }) =>-->
<!--            path.includes('/inbox/') ||-->
<!--            path.includes('/draft/') ||-->
<!--            path.includes('/sent/') ||-->
<!--            path.includes('/deleted/') ||-->
<!--            path.includes('/template/')-->
<!--        )-->
<!--      "-->
<!--    >-->
<!--      <div class="settings-back-btn">-->
<!--        <a class="c-sidebar-nav-link" id="goBackLab-2" @click="goBack">-->
<!--          <CIcon-->
<!--            name="cilArrowCircleLeft"-->
<!--            class="c-sidebar-nav-icon"-->
<!--            width="24"-->
<!--            :height="45"-->
<!--          ></CIcon>-->
<!--          <span class="go-back-lab&#45;&#45;title">Back</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div-->
<!--        class="nav-divider"-->
<!--        :class="{ 'nav-divider__minimize': minimize }"-->
<!--      ></div>-->
<!--      <ClSidebarItems :items="$options.messagesNav[0]._children" />-->
<!--    </div>-->
    <!--  MAIN NAVIGATION  -->
    <div v-else class="h-100" style="overflow-y: auto">
      <ClSidebarItems
        :items="navItems"
        :logo-type="navLogoType"
      />
    </div>
  </CSidebar>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ClSidebarItems from "@/shared/components/ClSidebarItems";
import settingsNav from "./settingsNav";
import messagesNav from "./messagesNav";
import { isProd } from "@/helpers/keycloak";

export default {
  name: "TheSidebar",
  settingsNav,
  messagesNav,
  components: {
    ClSidebarItems,
  },
  data() {
    return {
      sidebarTitle: "Ziqni",
      appSwitcherInstance: null,
      spaceName: localStorage.getItem("zq-space-name"),
      spaceToken: localStorage.getItem("zq-space-name"),
      spaceData: [],
      navItems: []
    };
  },
  props: {
    laboratoryPage: Boolean,
  },
  computed: {
    ...mapGetters("theme", ["navItemsMain", "theme"]),
    ...mapGetters("spaces", ["spaces"]),
    ...mapState({
      sidebarShow: (state) => state.sidebarShow,
    }),
    show() {
      return this.laboratoryPage && this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    // Set default or new ICON
    navLogoType() {
      if (this.theme === "default") {
        return "svg";
      } else {
        return "img";
      }
    },
    devOrProdLocation() {
      return isProd ? "identity" : "identity";
    },
  },
  methods: {
    ...mapActions("spaces", [
      "handleGetSpaceInformation",
      "handleAppSwitcherCreated",
    ]),
    async initialize() {
      this.navItems = this.editMenuItemName(this.navItemsMain._children, 'Web Assets', 'CMS')

      await this.handleGetSpaceInformation();
    },
    editMenuItemName (menuItems, oldName, newName) {
      return menuItems.map(({name, ...menuItem}) => ({
        ...menuItem,
        name: oldName === name ? newName : name,
      }));
    },
    appSwitcherInit() {
      return this.spaces;
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/dashboard" });
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style type="text/css" lang="scss">
#goBackLab,
#goBackLab-2 {
  cursor: pointer;
  background: transparent;
  color: rgb(255, 255, 255);
  font-size: 13px;
}

.c-sidebar-brand-full {
  width: var(--sidebar-width);
  text-align: left;
  .logo {
    width: 70%;
    margin-left: 2.0em;
  }
}

.c-sidebar {
  background: var(--sider-bar-bg);

  .c-sidebar-brand {
    background: #000 !important;
  }

  &:hover .diamond-shape {
    opacity: 1;
  }

  .c-sidebar-minimizer::before {
    left: 1.1rem !important;
    background-size: 11px !important;
  }

  .c-sidebar-nav-link > .zq-sidebar-custom-logo {
    opacity: 0.4;
  }

  .c-sidebar-nav-link.router-link-exact-active.router-link-active {
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.05) !important;
    opacity: 1;
  }

  .c-sidebar-nav-link.router-link-exact-active.router-link-active
    .zq-sidebar-custom-logo {
    color: #ffffff;
    opacity: 1;
  }
}

.c-sidebar-backdrop,
.c-show {
  height: 100%;
}

.diamond-shape {
  opacity: 0;
  cursor: pointer;
  background: white;
  height: 22px;
  width: 25px;
  text-align: center;
  transform: translateX(3px) rotate(45deg);
  margin-left: 15rem;
  margin-top: -10px;
  border-bottom-left-radius: var(--sider-bar-border-radius);
  transition: 0.3s;
  z-index: 100;

  &:before {
    content: "";
    height: 15px;
    width: 10px;
    background: #ffffff;
    top: -4px;
    left: 0;
    position: absolute;
  }

  &:after {
    content: "";
    height: 2px;
    width: 10px;
    background: #ffffff;
    bottom: 0;
    right: -5px;
    position: absolute;
  }

  &__visible {
    opacity: 1;
  }
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed {
  .item-count {
    position: relative;
    display: flex;
    height: 21px;
    width: 21px;
    transform: rotate(-45deg);

    &--bg {
      position: absolute;
      width: 21px;
      height: 21px;
      background: var(--sider-bar-bg);
      transform: translate(3px, 0px) rotate(45deg);
      border-radius: var(--sider-bar-border-radius);
    }

    .c-sidebar-minimizer {
      position: absolute;
      z-index: 100 !important;

      &:hover {
        background-color: transparent !important;
      }

      &:before {
        width: 21px !important;
        height: 21px !important;
        top: 0 !important;
        left: 1px !important;
        position: absolute;
        z-index: 10;
      }
    }
  }
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed.c-sidebar-minimized {
  .item-count {
    .c-sidebar-minimizer {
      &:before {
        top: 0 !important;
        left: 3px !important;
      }
    }
  }
}

.zq-nav-divider {
  &::before {
    content: "";
    position: relative;
    display: flex;
    left: 10px;
    top: 0;
    height: 1px;
    width: 90%;
    background: grey;
  }
}

// sidebar minimized
.c-sidebar-minimized {
  .zq-nav-divider {
    &::before {
      width: 70%;
    }
  }

  .btn {
    padding: 0 0.005rem !important;
  }
}

.app-reporting-analytics,
.app-autherie {
  cursor: not-allowed;
  pointer-events: none;
}
</style>