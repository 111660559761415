<template>
  <div class="c-app">
    <TheSidebar :laboratoryPage="laboratoryPage"/>
    <CWrapper>
      <TheHeader :laboratoryPage="laboratoryPage"/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {sideBarHelpers} from '@/helpers/sideBarHelpers'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  data() {
    return {
      laboratoryRoutes: [
        'Laboratory',
        'CreateSpace',
        'PreviewSpace',
        'EditSpace',
      ],
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    laboratoryPage() {
      // return this.$route.name !== 'Laboratory';
      return !this.laboratoryRoutes.includes(this.$route.name );
    }
  },
  watch: {
    laboratoryPage() {
      return null
    }
  },
  mounted() {
    sideBarHelpers.setActiveLink(this.$router.currentRoute, this.theme);
  },
  updated() {
    sideBarHelpers.setActiveLink(this.$router.currentRoute, this.theme)
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
