<template>
  <div
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      class="zq--alert--wrap"
  >
    <CAlert
        :color="success ? 'success':  'danger'"
        :show="show"
        close-button
        class="zq--alert"
        v-on:update:show="handleUpdateShow"
    >
      {{ alertMessage }}
    </CAlert>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { delay } from 'lodash';

let timer;
export default {
  name: 'Alert',
  data() {
    return {
      show: false,
      alertMessage: '',
    }
  },
  props: {
    message: {
      type: String,
      default: null,
    },
    success: {
      type: Boolean,
      default: false,
    },
    isAutoHide: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    message(val) {
      if (val) {
        this.alertMessage = val.replaceAll('_', ' ').replace('error.', 'Error: ');
        this.showAlert();
      }
    }
  },
  methods: {
    ...mapActions('members', {membersClearMessage: 'handleClearMessage'}),
    ...mapActions('products', {productsClearMessage: 'handleClearMessage'}),
    ...mapActions('fileRepositories', {reposClearMessage: 'handleClearMessage'}),
    ...mapActions('files', {filesClearMessage: 'handleClearMessage'}),
    ...mapActions('events', {eventsClearMessage: 'handleClearMessage'}),
    ...mapActions('actionTypes', {actionTypesClearMessage: 'handleClearMessage'}),
    ...mapActions('rewardTypes', {rewardTypesClearMessage: 'handleClearMessage'}),
    ...mapActions('rewards', {rewardsClearMessage: 'handleClearMessage'}),
    ...mapActions('rules', {rulesClearMessage: 'handleClearMessage'}),
    ...mapActions('translations', {translationsClearMessage: 'handleClearMessage'}),
    ...mapActions('customFields', {customFieldsClearMessage: 'handleClearMessage'}),
    ...mapActions('languages', {languagesClearMessage: 'handleClearMessage'}),
    ...mapActions('unitsOfMeasure', {unitsClearMessage: 'handleClearMessage'}),
    ...mapActions('webhooks', {webhooksClearMessage: 'handleClearMessage'}),
    ...mapActions('connections', {connectionsClearMessage: 'handleClearMessage'}),
    ...mapActions('transformers', {transformerClearMessage: 'handleClearMessage'}),
    ...mapActions('apiKeys', {apiKeysClearMessage: 'handleClearMessage'}),
    ...mapActions('achievements', {achievementsClearMessage: 'handleClearMessage'}),
    ...mapActions('tags', {tagsClearMessage: 'handleClearMessage'}),
    ...mapActions('messages', {messagesClearMessage: 'handleClearMessage'}),
    ...mapActions('contests', {contestsClearMessage: 'handleClearMessage'}),
    ...mapActions('competitions', {competitionsClearMessage: 'handleClearMessage'}),
    ...mapActions('collaborators', {collaboratorsClearMessage: 'handleClearMessage'}),
    ...mapActions('sqsConnections', {sqsConnectionsClearMessage: 'handleClearMessage'}),
    ...mapActions('kafkaConnections', {kafkaConnectionsClearMessage: 'handleClearMessage'}),
    ...mapActions('rabbitMqConnections', {rabbitMqConnectionsClearMessage: 'handleClearMessage'}),
    ...mapActions('notifications', {notificationsClearMessage: 'handleClearMessage'}),
    ...mapActions('instantWins', {instantWinsClearMessage: 'handleClearMessage'}),
    onMouseEnter() {
      clearTimeout(timer);
    },
    onMouseLeave() {
      this.showAlert();
    },
    showAlert() {
      if (!this.alertMessage) {
        return;
      }

      if (this.alertMessage === 'Success') {
        delay(() => {
          this.show = true;
        }, 1200)
      } else {
        this.show = true;
      }

      clearTimeout(timer);

      if (this.isAutoHide) {
        setTimeout(() => {
          this.hideAlert();
        }, 6 * 1000);
      }
    },
    hideAlert() {
      this.show = false;
      this.alertMessage = '';
      // Clear all messages in State after closing
      this.membersClearMessage();
      this.productsClearMessage();
      this.eventsClearMessage();
      this.filesClearMessage();
      this.reposClearMessage();
      this.actionTypesClearMessage();
      this.rewardTypesClearMessage();
      this.rewardsClearMessage();
      this.rulesClearMessage();
      this.translationsClearMessage();
      this.customFieldsClearMessage();
      this.languagesClearMessage();
      this.unitsClearMessage();
      this.webhooksClearMessage();
      this.connectionsClearMessage();
      this.transformerClearMessage();
      this.apiKeysClearMessage();
      this.achievementsClearMessage();
      this.tagsClearMessage();
      this.contestsClearMessage();
      this.competitionsClearMessage();
      this.collaboratorsClearMessage();
      this.sqsConnectionsClearMessage();
      this.kafkaConnectionsClearMessage();
      this.rabbitMqConnectionsClearMessage();
      this.notificationsClearMessage();
      this.instantWinsClearMessage();
    },
    handleUpdateShow(value) {
      if (!value) {
        this.hideAlert();
      }
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.zq--alert--wrap {
  position: absolute;
  z-index: 2000;
  left: 30%;
  right: 30%;
  top: 5.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  @include media-breakpoint-down('768px') {
    left: 56px;
    right: 56px;
  }

  @include media-breakpoint-down('576px') {
    left: 15px;
    right: 15px;
  }

  .zq--alert {
    margin: 0;

    .close {
      outline: none;
    }

    @include media-breakpoint-down('768px') {
      width: 100%;
    }
  }
}
</style>
