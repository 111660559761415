<template>
  <CDropdown
    inNav
    class="c-header-nav-items zq--header-nav-actions--avatar"
    placement="bottom-end"
    @update:show="dropdownEvent"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar" v-if="avatar">
          <img
            src="img/avatars/6.jpg"
            class="c-avatar-img"
            alt="avatar"
          />
        </div>
        <div v-if="!avatar" class="empty-avatar">
          <div class="empty-avatar--content">
            {{ userName }}
          </div>
        </div>
      </CHeaderNavLink>
    </template>
    <!--  HEADER  -->
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ userData.email }}</strong>
<!--            <strong>user@ziqni-tech.com</strong>-->
    </CDropdownHeader>
    <!-- CONTENT   -->
    <div class="zq--box-msg">
      <CDropdownItem
        :href="`https://${devOrProdLocation}.ziqni.com/realms/ziqni/account/`"
        target="_blank"
      >
        <CIcon name="cil-user"/>
        Account
      </CDropdownItem>
      <CDropdownItem
        v-if="laboratoryPage"
        @click="getCollaborators"
      >
        <CIcon name="cil-group"/>
        Collaborators
      </CDropdownItem>
      <CDropdownItem
        v-if="laboratoryPage"
        @click="getSpace"
      >
        <CIcon name="cil-cloud"/>
        Space
      </CDropdownItem>
      <CDropdownItem
        v-if="laboratoryPage"
        @click="getMessages"
      >
        <CIcon name="cil-envelope-open"/>
        Inbox
      </CDropdownItem>
    </div>

    <CDropdownItem class="zq--box--logout">
      <CButton
          class="zq--box--action-button"
          @click="handleLogout"
      >
        <CIcon name="cil-lock-locked"/>
        Logout
      </CButton>

    </CDropdownItem>
  </CDropdown>
</template>

<script>
  import {keycloak, isProd} from "@/helpers/keycloak";

  export default {
    name: 'TheHeaderDropdownAccnt',
    props: {
      userData: Object,
      laboratoryPage: {
        type:Boolean,
        default: true
      },
    },
    computed: {
      userName() {
        return this.userData.name ? this.userData.name.split(' ').map(item => item[0].toUpperCase()).join('') : 'UR'
      },
      devOrProdLocation() {
        return isProd ? 'identity': 'identity'
      },
    },
    data() {
      return {
        itemsCount: 42,
        avatar: '',
        opened: true,
      }
    },
    methods: {
      getCollaborators() {
        if (this.$route.name !== 'Collaborators') {
          this.$router.push({path: '/settings/collaborators'});
        }
      },
      getSpace() {
        if (this.$route.name !== 'Space') {
          this.$router.push({path: '/space'});
        }
      },
      getMessages() {
        if (this.$route.name !== 'Space') {
          this.$router.push({path: '/account-messages'});
        }
      },
      handleLogout() {
        // console.log('keycloak', keycloak);
        keycloak.logout({
          redirectUri: 'https://ziqni.com'
        })
      },
      dropdownEvent() {
        this.opened = !this.opened;
        this.$emit('dropdownEvent', this.opened)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .c-icon {
    margin-right: 0.3rem;
  }

  .empty-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: #007dfb;
    border-radius: 50%;
    margin-top: 0.2rem;
    margin-left: 3px;

    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    transition-delay: 0s, 0s, 0s, 0s, 0s;
    .empty-avatar--content {
      font-size: 20px;
      color: #ffffff;
    }
    &:hover {
      background-color: #0056ad;
    }
  }

  .c-header-nav-items.dropdown.nav-item {
    a {
      padding-right: 0;
    }
  }
</style>