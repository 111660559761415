<template>
  <div class="mr-4 zq--header-nav-actions">
    <!--   UTC TIME   -->
    <div
      class="row"
      style="    background: #fff;
    border-radius: 50px;
    margin-top: 10px;
    max-width: 13rem;"
    >
      <div class="col">
        <UtcWidget />
      </div>
      <div class="col">
        <CHeaderNavItem
          v-if="laboratoryPage"
          class="zq--header-nav-actions--notify mx-8"
          :class="{ 'zq-disable': disabled }"
        >
          <CDropdown class="c-header-nav-items" placement="bottom-end" :caret="false">
            <template #toggler-content>
              <CHeaderNavLink>
                <img src="../../assets/icons/icons-notif.svg" alt="bell" height="28" width="28" />
                <CBadge v-if="!disabled" color="danger" shape="pill" class="mfs-auto">3</CBadge>
              </CHeaderNavLink>
            </template>
            <!--   Dropdown Header      -->
            <CDropdownHeader>
              <span>Notifications</span>
            </CDropdownHeader>

            <div class="dropdown-item" v-for="(item, index) in bellsMessages" :key="index">
              <div class="zq--box-msg">
                <div>
                  <div class="zq--box-msg--header">
                    <span class="zq--box-msg--title">{{ item.title }}</span>
                    <div class="zq--box-msg--header-close" @click="handleDeleteMessage">
                      <span>&times;</span>
                    </div>
                  </div>

                  <div class="zq--box-msg--content">{{ textShorten(item.message) }}</div>
                </div>
                <CLink class="zq--box-msg--link" :to="item.link">Read All</CLink>
              </div>
            </div>

            <CDropdownItem :to="{ name: 'Inbox' }">
              <CButton class="zq--box--action-button">Go to All Messages</CButton>
            </CDropdownItem>
          </CDropdown>
        </CHeaderNavItem>
      </div>
      <div class="col">
        <TheHeaderDropdownAccnt
          :userData="userData"
          :laboratoryPage="laboratoryPage"
          @dropdownEvent="dropdownEvent"
        />
      </div>
    </div>
    <!--   BELL   -->
  </div>
</template>

<script>
import TheHeaderDropdownAccnt from '@/components/header/TheHeaderDropdownAccnt';
import UtcWidget from '@/shared/UI/UtcWidget';
import { textShorten } from '@/utils/textUtils';
import { decodeJwt } from "@/helpers/decodeJwt";

export default {
  components: {
    TheHeaderDropdownAccnt,
    UtcWidget
  },
  data() {
    return {
      disabled: true,
      userData: {},
      bellsMessages: [
        {
          title: 'Congratulations!',
          message: 'There is such a lot of talk going around about branding, but what exactly is your brand and how do you use it to help you reach more',
          link: { name: "Inbox", params: { id: "dAWsA3UBen7u-SEkZ6Fi" } }
        },
        {
          title: 'Congratulations!',
          message: 'This is Test Message asdasd dasd asdasdas das ad as',
          link: { name: "Inbox", params: { id: "z_zuMm8BhoCA-ASXKnuw" } }
        },
        {
          title: 'Congratulations!',
          message: 'This is Test Message',
          link: { name: "Inbox", params: { id: "yfzuMm8BhoCA-ASXJ3ts" } }
        },
      ]
    }
  },
  props: {
    laboratoryPage: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    this.userData = decodeJwt(localStorage.getItem('vue-token'))
  },
  methods: {
    handleDeleteMessage() {
    },
    textShorten(text) {
      return textShorten(text, 120);
    },
    dropdownEvent(val) {
      this.$emit('dropdownEvent', val)
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins";
.mx-8 {
  margin-left: 1rem;
}
.zq-nav-actions-utc--bell-of {
  margin-right: 12px;
}

.c-header {
  .c-header-nav.zq--header-nav-actions {
    .dropdown-item {
      height: auto;
    }
  }
}

// BELl
.zq--header-nav-actions {
  .zq--header-nav-actions--notify {
    .c-header-nav-items.dropdown.show {
      .dropdown-menu.show {
        background-color: #f3f3f3;
        margin-top: 4px !important;
        padding-bottom: 0 !important;
      }

      .zq--box--action-button {
        height: 40px;
        width: 100%;
        margin-bottom: 16px;
        margin-top: 16px;
        border: 1px solid black;
        border-radius: 7px;
        outline: none;
        color: black;
        font-size: 0.875rem;

        &:hover {
          color: #fff;
          background-color: #636f83;
          border-color: #636f83;
        }
      }
    }

    .dropdown-header {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #282f37;
    }

    .dropdown-item {
      padding: 0 24px;
      margin-bottom: 8px;
      white-space: normal;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

// BOX
.zq--box-msg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  width: inherit;
  padding: 12px 16px 10px;
  background-color: #ffffff;

  height: 160px;
  max-width: 344px;

  .zq--box-msg--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    margin: 0 0 10px 0;
    color: #282f37;

    .zq--box-msg--title {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
    }

    .zq--box-msg--header-close {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      width: 40px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .zq--box-msg--content {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #282f37;
  }

  .zq--box-msg--link {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #e74a39;
    height: 40px;
    margin: 4px 10px 0 0;
  }

  @include media-breakpoint-down("576px") {
    max-width: fit-content;
    height: fit-content;
  }
}

// AVATAR
.c-header-nav-items.zq--header-nav-actions--avatar.dropdown.show.nav-item {
  .dropdown-menu.show {
    background-color: #f3f3f3;
    margin-top: 7px !important;
    padding-bottom: 0 !important;

    .dropdown-header {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #282f37;
      background-color: #f3f3f3 !important;
    }

    .dropdown-item {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      padding-left: 16px;

      &:hover {
        background-color: rgba(231, 74, 57, 0.4);
        color: #0080a2;
      }
    }

    .zq--box-msg {
      min-width: 210px;
      width: auto;
      margin: 0 20px;
      padding: 12px 0 10px 0;
      height: auto;
    }

    .zq--box--logout {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 1;
        background-color: transparent;
      }
    }

    .zq--box--action-button {
      height: 40px;
      width: 100%;
      border-radius: 7px;
      border: solid 1px #282f37;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #282f37;

      &:hover {
        color: #fff;
        background-color: #636f83;
        border-color: #636f83;
      }
    }
  }
}

.zq-disable {
  opacity: 0.4;
  pointer-events: none;
}

@include media-breakpoint-down("576px") {
  .zq-nav-actions-utc {
    display: none;
  }
}
</style>