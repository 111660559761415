<template>
  <CSubheader class="breadcrumbs-subheader">
    <CBreadcrumb v-if="laboratoryPage" :items="items" class="border-0 mb-0" />
  </CSubheader>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Breadcrumb",
  data() {
    return {
      items: [],
    };
  },
  props: {
    laboratoryPage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("path", {
      breadcrumbItems: "breadcrumb",
    }),
  },
  watch: {
    breadcrumbItems(val) {
      this.items = val;
    },
  },
};
</script>

<style lang="scss">
.breadcrumbs-subheader {
  padding-left: 0.5rem;
  word-break: break-word;
}
</style>